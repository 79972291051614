// JS for marketplace website

$(document).ready(function() {

    // fix menu when passed
    $('.masthead') .visibility({
        once: false,
        onBottomPassed: function() {
            $('.fixed.menu').transition('fade in');
        },
        onBottomPassedReverse: function() {
            $('.fixed.menu').transition('fade out');
        }
    });

    // create sidebar and attach to menu open
    $('.ui.sidebar').sidebar('attach events', '.toc.item');

    // WEBSITE

    $('.featured-products .image').dimmer({
        on: 'hover'
    });

    // SHOP

    $('.store-products .image').dimmer({
        on: 'hover'
    });

});